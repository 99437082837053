.main-viewport{
	display: flex;
	align-items:center;
	justify-content: center;
	flex-direction: row;
	background-color: #FFFFFF;
	height: 100%;
	width: 100%;

	@media (max-width: 768px) {
			flex-direction: column;
	}
}

body{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 90vh;
	background-color: #FFFFFF;
}

