.model-viewer {
	height: 700px;
	width: 800px;

	background-repeat: no-repeat;
	background-image: url('../../../public/assets/bg-box-gray.png');
	background-size: contain;
	background-color: rgba(255, 255, 255, 0.67);
	background-position: center;
	background-blend-mode: lighten;

	@media (max-width: 768px) {
		height: 30vh;
		width: 100vw;
	}

	.default-ar-button {
		display: none;
	}

}
