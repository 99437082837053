.error-page {
	display: flex;
	width: 90vw;
	height: 90vh;
	justify-content: center;
	align-items: center;
	flex-flow: column;
	&__h2{
		font-family: 'Novarese Swarovski Medium';
		font-size: 30px;
		margin-bottom: 30px;
	}
	&__text {
		margin-bottom: 30px;
		line-height: 19px;
	}
}
