@import "src/scss/Fonts";

:host {
  width: 100vw;
  height: 100vh;
}

.swa3d {
  display: block;
  text-align: left;
  overflow-x: hidden;

  &-hide {
    display: none;
    opacity: 0;
  }

  &-header-test-site {
    display: none;
  }

  // BUTTONS
  .button {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    margin: 16px 0 0;
    font-family: "EuclidIgnited", sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.04rem;
    padding: .9rem 2.5rem;
    text-align: center;
    border: 0;
    transition: .4s ease-in-out;
    text-decoration: none !important;
    cursor: pointer;

    &--primary {
      color: #fff;
      background-color: #000000;

      @media (hover: hover) {
        &:hover {
          background: transparent;
          color: #000;
          box-shadow: inset 0 0 0 0.1rem #000;
        }
      }
    }

    &--secondary {
      color: #000;
      background-color: transparent;
      box-shadow: inset 0 0 0 0.1rem #000;

      @media (hover: hover) {
        &:hover {
          background: #000;
          color: #fff;
        }
      }
    }

    &--tertiary {
      color: #000;
      text-align: left;
      margin: 8px 0 0;
      padding: 0;
      background: transparent;
      letter-spacing: 0;
    }
  }

  // TEXT STYLES
  &-text {
    font-family: "EuclidIgnited", "NotoSans", sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    color: #353535;

    &-center {
      text-align: center;
    }
  }

  h2,
  .h2 {
    @extend .swa3d-text;
    font-family: "ITCNovareseMed", "NotoSans", sans-serif;
    font-size: 2.5rem;
    line-height: 1.6;
    margin: 0;
    margin-bottom: 16px;
  }

  h3,
  .h3 {
    @extend .swa3d-text;
    font-family: "ITCNovareseMed", "NotoSans", sans-serif;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.7rem;
    margin: 8px 0;
  }

  h4,
  .h4 {
    @extend .swa3d-text;
    font-size: 1.2rem;
    line-height: 1.19;
    margin: 8px 0;
  }

  .copy {
    @extend .swa3d-text;
    font-size: 1.4rem;
    line-height: 1.45;
  }

  .uppercase {
    text-transform: uppercase;
  }

  // INPUTS

  &-section {
    width: 100%;

    @media screen and (min-width: 1024px){
      max-width: 344px;
      margin: auto;
    }
  }

  &-input-container {
    position: relative;
    margin: 16px 0 0;
  }

  input,
  textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    font-family: "NotoSans", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    border: 1px solid #d2d2d7;
  }

  input {
    height: 4.8rem;
    padding: 1px 11px;
  }

  textarea {
    height: auto;
    margin: 16px 0 0;
    padding: 10px;
    resize: none;
  }

  input:focus,
  textarea:focus {
    outline: none;
    border: 1px solid rgb(110, 110, 115);
  }

  textarea::placeholder {
    color: #acacac;
    font-size: 1.6rem;

    // for firefox
    opacity: 1;
  }

  &-input-container label {
    @extend .swa3d-text;
    cursor: text;
    position: absolute;
    top: 13px;
    left: 13px;
    color: #acacac;
    transition: transform .25s ease;
    transform-origin: left;
  }

  input:focus,
  input:not(:placeholder-shown) {
    padding-top: 1.8rem;
  }

  input:focus + label,
  input:not(:placeholder-shown) + label {
    color: #6e6e73;
    transform: translateY(-10px) scale(0.65);
  }

  input.swa3d-error,
  textarea.swa3d-error {
    border: solid 1px #e30000;
  }

  &-error-message {
    width: 100%;
    padding-left: 1.1rem;
    font-family: "NotoSans", sans-serif;
    font-size: 1rem;
    line-height: 2rem;
    color: #e30000;
    background-color: #fff2f4;
  }
}

@media only screen and (min-width: 1024px) {
  .swa3d {
    button {
      margin: 20px 0 0 0 !important;
    }

    &-header-test-site {
      display: block;
    }

    h2 {
      font-size: 3.2rem;
      line-height: 3.8rem;
      margin: 24px 0;
    }

    .copy {
      font-size: 1.6rem;
    }

    @media (min-height: 897px) {
      .swa3d {
        input {
          margin: 16px 0 0;
        }

        &-input-container {
          margin: 0;
        }
      }
    }
  }
}
