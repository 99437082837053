
.info-wrapper {
	display: flex;
	flex-direction: column;
	width: 40%;
	padding: 2rem;
	max-width: 450px;
	align-items: center;
	&_h2 {
		font-family: 'Novarese Swarovski Medium', sans-serif;
		font-size: 32px;
		margin-bottom: 30px;
		font-weight: normal;
		text-align: center;
		line-height: 120%;
	}
	&_text {
		font-family: 'EuclidIgnited Regular', sans-serif;
		font-size: 18px;
		margin-bottom: 40px;
		line-height: 160%;
		letter-spacing: 0.025em;
		text-align: center;

		&--bold {
			font-family: 'EuclidIgnited Semibold', sans-serif;
			font-size: 18px;
			max-width: 300px;
			line-height: 22px;

		}
	}
	&_button {
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'EuclidIgnited Semibold', sans-serif;
	text-decoration: none;
	color: white !important;
	background-color: black;
	max-width: 400px;
	padding: 1rem .5rem;
	text-align: center;
	border: none;

		>svg {
			margin-right: 16px;
		}
	}

	&_hint {
		font-family: 'EuclidIgnited Regular', sans-serif;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 16px;
	}

	@media (max-width: 768px) {
			width: 90%;
			justify-content: center;
			padding: 1rem 1rem 1rem 1rem;

		&_h2{
			font-size: 32px;
			margin-top: 15px;
			margin-bottom: 25px;
		}

		&_button{
			width: 100%;
		}

		&_text {
			margin-top: 0;
		}
	}
}
