
@font-face {
	font-family: 'EuclidIgnited Regular';
	font-style: normal;
	font-weight: 400;
	src:  url('../../public/assets/fonts/Euclid-Ignited/EuclidIgnited-Regular-WebXL.woff2') format('woff2'),
	url('../../public/assets/fonts/Euclid-Ignited/EuclidIgnited-Regular-WebXL.woff') format('woff'),
	url('../../public/assets/fonts/Euclid-Ignited/EuclidIgnited-Regular.ttf') format('ttf')
}
@font-face {
	font-family: 'EuclidIgnited Semibold';
	font-style: normal;
	font-weight: 600;
	src:  url('../../public/assets/fonts/Euclid-Ignited/EuclidIgnited-Semibold-WebXL.woff2') format('woff2'),
	url('../../public/assets/fonts/Euclid-Ignited/EuclidIgnited-Semibold-WebXL.woff') format('woff'),
	url('../../public/assets/fonts/Euclid-Ignited/EuclidIgnited-Semibold.ttf') format('ttf')
}
@font-face {
	font-family: 'Novarese Swarovski Black';
	font-style: normal;
	font-weight: 300;
	src:  url('../../public/assets/fonts/Novarese-Swarovski/ITCNovareseforSwarovskiBk.woff2') format('woff2'),
	url('../../public/assets/fonts/Novarese-Swarovski/ITCNovareseforSwarovskiBk.woff') format('woff'),
	url('../../public/assets/fonts/Novarese-Swarovski/ITCNovareseforSwarovskiBk.ttf') format('ttf')
}
@font-face {
	font-family: 'Novarese Swarovski Medium';
	font-style: normal;
	font-weight: 200;
	src:  url('../../public/assets/fonts/Novarese-Swarovski/ITCNovareseforSwarovskiMd.woff2') format('woff2'),
	url('../../public/assets/fonts/Novarese-Swarovski/ITCNovareseforSwarovskiMd.woff') format('woff'),
	url('../../public/assets/fonts/Novarese-Swarovski/ITCNovareseforSwarovskiMd.ttf') format('ttf')
}
