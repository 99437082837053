.modal-wrapper{
	display: flex;
	font-family: "EuclidIgnited Regular", sans-serif;
	font-size: 13px;
	position: fixed;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	padding: 16px;
	height: 200px;
	max-width: 100vw;
	background-color: #FAFAFA;
	left: 0;
	bottom: -250px;
	transition: bottom cubic-bezier(0.54,-0.06, 0.37, 1.03) .5s;
	overflow: hidden;
	box-shadow: 0 9px 132px rgba(0, 0, 0, 0.13), 0 2.01027px 29.4839px rgba(0, 0, 0, 0.16), 0 0.598509px 8.77813px rgba(0, 0, 0, 0.10);
	&__text{
		display: flex;
		width: 100%;
		margin-left: 2px;
		margin-bottom: 24px;
		line-height: 20px;

	}

	&__button{
		font-family: "EuclidIgnited Semibold", sans-serif;
		font-weight: bold;
		font-size: 13px;
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		color: white !important;
		background-color: black;
		max-width: 400px;
		padding: 1rem .5rem;
		text-align: center;
		border: none;
	}

	&__close{
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 10px;
		right: 12px;
		height: 20px;
		width: 20px;
	}

	&__icons {
		display: flex;
		width: 100%;
		flex-flow: row;
		justify-content: center;
		margin-bottom: 24px;
	}

	&__icon {
		display: flex;
		flex-flow: row;
		align-items: center;
		&:nth-child(2){
			margin: 0 16px;
		}

		&-text {
			font-family: "EuclidIgnited Semibold", sans-serif;
			font-size: 16px;
			margin-left: 4px;
			max-width: 70px;
			width: 100%;
		}

		@media (max-width: 410px) {

			>svg{
				height: 40px;
				width: 40px;
			}

			&-text {
				font-size: 14px;
				margin-left: 4px;
			}

		}
	}



}
